import React from 'react';

export function AddUserIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      className="mr-2 w-6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.375 9.10526C8.23896 9.10526 9.75 7.5147 9.75 5.55263C9.75 3.59057 8.23896 2 6.375 2C4.51104 2 3 3.59057 3 5.55263C3 7.5147 4.51104 9.10526 6.375 9.10526Z"
        fill="#6B7280"
      />
      <path
        d="M7.5 9.89474H5.25C4.2558 9.89599 3.30267 10.3123 2.59966 11.0523C1.89666 11.7923 1.50119 12.7956 1.5 13.8421V16.2105C1.5 16.4199 1.57902 16.6207 1.71967 16.7688C1.86032 16.9168 2.05109 17 2.25 17H10.5C10.6989 17 10.8897 16.9168 11.0303 16.7688C11.171 16.6207 11.25 16.4199 11.25 16.2105V13.8421C11.2488 12.7956 10.8533 11.7923 10.1503 11.0523C9.44733 10.3123 8.4942 9.89599 7.5 9.89474Z"
        fill="#6B7280"
      />
      <path
        d="M15.75 7.52632H14.25V5.94737C14.25 5.73799 14.171 5.53718 14.0303 5.38913C13.8897 5.24107 13.6989 5.15789 13.5 5.15789C13.3011 5.15789 13.1103 5.24107 12.9697 5.38913C12.829 5.53718 12.75 5.73799 12.75 5.94737V7.52632H11.25C11.0511 7.52632 10.8603 7.60949 10.7197 7.75755C10.579 7.9056 10.5 8.10641 10.5 8.31579C10.5 8.52517 10.579 8.72598 10.7197 8.87403C10.8603 9.02209 11.0511 9.10526 11.25 9.10526H12.75V10.6842C12.75 10.8936 12.829 11.0944 12.9697 11.2425C13.1103 11.3905 13.3011 11.4737 13.5 11.4737C13.6989 11.4737 13.8897 11.3905 14.0303 11.2425C14.171 11.0944 14.25 10.8936 14.25 10.6842V9.10526H15.75C15.9489 9.10526 16.1397 9.02209 16.2803 8.87403C16.421 8.72598 16.5 8.52517 16.5 8.31579C16.5 8.10641 16.421 7.9056 16.2803 7.75755C16.1397 7.60949 15.9489 7.52632 15.75 7.52632Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export function InboxFullIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      className="mr-2 w-6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2327 3.33725C14.1957 3.17058 14.1029 3.02154 13.9697 2.91476C13.8364 2.80798 13.6707 2.74986 13.5 2.75H4.5C4.32926 2.74986 4.16358 2.80798 4.03035 2.91476C3.89711 3.02154 3.8043 3.17058 3.76725 3.33725L2.4 9.5H5.57925C6.01628 9.50024 6.44372 9.62813 6.80906 9.86796C7.1744 10.1078 7.46173 10.4491 7.63575 10.85C7.74966 11.1175 7.93972 11.3457 8.18229 11.5061C8.42485 11.6664 8.70922 11.7519 9 11.7519C9.29078 11.7519 9.57515 11.6664 9.81771 11.5061C10.0603 11.3457 10.2503 11.1175 10.3643 10.85C10.5383 10.4491 10.8256 10.1078 11.1909 9.86796C11.5563 9.62813 11.9837 9.50024 12.4208 9.5H15.6L14.2327 3.33725ZM6.75 4.25H11.25C11.4489 4.25 11.6397 4.32902 11.7803 4.46967C11.921 4.61032 12 4.80109 12 5C12 5.19891 11.921 5.38968 11.7803 5.53033C11.6397 5.67098 11.4489 5.75 11.25 5.75H6.75C6.55109 5.75 6.36032 5.67098 6.21967 5.53033C6.07902 5.38968 6 5.19891 6 5C6 4.80109 6.07902 4.61032 6.21967 4.46967C6.36032 4.32902 6.55109 4.25 6.75 4.25ZM12 8H6C5.80109 8 5.61032 7.92098 5.46967 7.78033C5.32902 7.63968 5.25 7.44891 5.25 7.25C5.25 7.05109 5.32902 6.86032 5.46967 6.71967C5.61032 6.57902 5.80109 6.5 6 6.5H12C12.1989 6.5 12.3897 6.57902 12.5303 6.71967C12.671 6.86032 12.75 7.05109 12.75 7.25C12.75 7.44891 12.671 7.63968 12.5303 7.78033C12.3897 7.92098 12.1989 8 12 8Z"
        fill="#6B7280"
      />
      <path
        d="M3 16.25H15C15.1989 16.25 15.3897 16.171 15.5303 16.0303C15.671 15.8897 15.75 15.6989 15.75 15.5V11H12.4208C12.2757 11.0004 12.134 11.0432 12.0129 11.1231C11.8919 11.2031 11.797 11.3167 11.7397 11.45C11.509 11.9852 11.1266 12.4411 10.6397 12.7614C10.1528 13.0817 9.5828 13.2524 9 13.2524C8.4172 13.2524 7.84716 13.0817 7.36027 12.7614C6.87339 12.4411 6.49097 11.9852 6.26025 11.45C6.20303 11.3167 6.10806 11.2031 5.98705 11.1231C5.86605 11.0432 5.72429 11.0004 5.57925 11H2.25V15.5C2.25 15.6989 2.32902 15.8897 2.46967 16.0303C2.61032 16.171 2.80109 16.25 3 16.25Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export function AddUserOutlineIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      className="mr-2 w-6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 7.25V9.5V7.25ZM13.5 9.5V11.75V9.5ZM13.5 9.5H15.75H13.5ZM13.5 9.5H11.25H13.5ZM9.75 5.75C9.75 6.54565 9.43393 7.30871 8.87132 7.87132C8.30871 8.43393 7.54565 8.75 6.75 8.75C5.95435 8.75 5.19129 8.43393 4.62868 7.87132C4.06607 7.30871 3.75 6.54565 3.75 5.75C3.75 4.95435 4.06607 4.19129 4.62868 3.62868C5.19129 3.06607 5.95435 2.75 6.75 2.75C7.54565 2.75 8.30871 3.06607 8.87132 3.62868C9.43393 4.19129 9.75 4.95435 9.75 5.75V5.75ZM2.25 15.5C2.25 14.3065 2.72411 13.1619 3.56802 12.318C4.41193 11.4741 5.55653 11 6.75 11C7.94347 11 9.08807 11.4741 9.93198 12.318C10.7759 13.1619 11.25 14.3065 11.25 15.5V16.25H2.25V15.5Z"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LogOutIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      className="mr-2 w-6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4259 9.32255L14.7795 8.969L14.4259 9.32256C14.4482 9.34483 14.4667 9.37191 14.4804 9.40501C14.5053 9.4654 14.5053 9.5346 14.4804 9.59499C14.466 9.62966 14.4475 9.65616 14.4279 9.67553L14.4259 9.67745L11.4267 12.6767C11.3778 12.7256 11.3145 12.75 11.25 12.75C11.1855 12.75 11.1222 12.7256 11.0733 12.6767C10.9753 12.5787 10.9753 12.4213 11.0733 12.3233L12.7931 10.6036L13.6466 9.75H12.4395H6C5.86172 9.75 5.75 9.63818 5.75 9.5C5.75 9.36182 5.86172 9.25 6 9.25H12.4395H13.6466L12.7931 8.39645L11.0733 6.6767C10.9753 6.57871 10.9753 6.42129 11.0733 6.3233C11.1713 6.22532 11.3287 6.22532 11.4267 6.3233L14.4259 9.32255ZM5.25 14.5H7.5C7.63828 14.5 7.75 14.6118 7.75 14.75C7.75 14.8882 7.63828 15 7.5 15H5.25C4.28564 15 3.5 14.2144 3.5 13.25V5.75C3.5 4.78564 4.28564 4 5.25 4H7.5C7.63828 4 7.75 4.11182 7.75 4.25C7.75 4.38818 7.63828 4.5 7.5 4.5H5.25C4.55953 4.5 4 5.06093 4 5.75V13.25C4 13.9391 4.55953 14.5 5.25 14.5Z"
        fill="#374151"
        stroke="#F7931E"
      />
    </svg>
  );
}

export function PlusCircleIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8V10.5M10 10.5V13M10 10.5H12.5M10 10.5H7.5M17.5 10.5C17.5 11.4849 17.306 12.4602 16.9291 13.3701C16.5522 14.2801 15.9997 15.1069 15.3033 15.8033C14.6069 16.4997 13.7801 17.0522 12.8701 17.4291C11.9602 17.806 10.9849 18 10 18C9.01509 18 8.03982 17.806 7.12987 17.4291C6.21993 17.0522 5.39314 16.4997 4.6967 15.8033C4.00026 15.1069 3.44781 14.2801 3.0709 13.3701C2.69399 12.4602 2.5 11.4849 2.5 10.5C2.5 8.51088 3.29018 6.60322 4.6967 5.1967C6.10322 3.79018 8.01088 3 10 3C11.9891 3 13.8968 3.79018 15.3033 5.1967C16.7098 6.60322 17.5 8.51088 17.5 10.5Z"
        stroke="#237D86"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ImportIcon() {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 8.5H9.5M4.5 11.8333H9.5M11.1667 16H2.83333C2.39131 16 1.96738 15.8244 1.65482 15.5118C1.34226 15.1993 1.16667 14.7754 1.16667 14.3333V2.66667C1.16667 2.22464 1.34226 1.80072 1.65482 1.48816C1.96738 1.17559 2.39131 1 2.83333 1H7.48833C7.70933 1.00005 7.92126 1.08788 8.0775 1.24417L12.5892 5.75583C12.7455 5.91208 12.8333 6.124 12.8333 6.345V14.3333C12.8333 14.7754 12.6577 15.1993 12.3452 15.5118C12.0326 15.8244 11.6087 16 11.1667 16Z"
        stroke="#237D86"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function EditPenIcon() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6933 5.19035L15.64 8.13702M13.9433 3.94035C14.3341 3.5496 14.8641 3.33008 15.4167 3.33008C15.9693 3.33008 16.4992 3.5496 16.89 3.94035C17.2808 4.33111 17.5003 4.86108 17.5003 5.41369C17.5003 5.96629 17.2808 6.49627 16.89 6.88702L5.41667 18.3604H2.5V15.3837L13.9433 3.94035Z" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

export function ThreeDotsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 14C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12C18 13.1046 18.8954 14 20 14Z"
        fill="#1F2A37"
      />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        fill="#1F2A37"
      />
      <path
        d="M4 14C5.10457 14 6 13.1046 6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14Z"
        fill="#1F2A37"
      />
    </svg>
  );
}

export function YellowDeleteIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      className="mr-2 w-6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 8.41934V12.9193M10.5 8.41934V12.9193M3 5.41934H15M14.25 5.41934L13.5997 14.5258C13.5728 14.9043 13.4035 15.2585 13.1258 15.517C12.8482 15.7756 12.4829 15.9194 12.1035 15.9193H5.8965C5.5171 15.9194 5.1518 15.7756 4.87416 15.517C4.59653 15.2585 4.42719 14.9043 4.40025 14.5258L3.75 5.41934H14.25ZM11.25 5.41934V3.16934C11.25 2.97043 11.171 2.77966 11.0303 2.63901C10.8897 2.49836 10.6989 2.41934 10.5 2.41934H7.5C7.30109 2.41934 7.11032 2.49836 6.96967 2.63901C6.82902 2.77966 6.75 2.97043 6.75 3.16934V5.41934H11.25Z"
        stroke="#F7931E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EditFillPenIcon() {
  return (
    <svg
      width="18"
      height="19"
      className="mr-2 w-6"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2274 3.39674C12.3935 3.22482 12.5921 3.0877 12.8117 2.99336C13.0313 2.89902 13.2675 2.84937 13.5065 2.84729C13.7455 2.84521 13.9825 2.89076 14.2037 2.98126C14.4249 3.07177 14.6259 3.20542 14.7949 3.37443C14.9639 3.54344 15.0976 3.74441 15.1881 3.96562C15.2786 4.18684 15.3241 4.42386 15.3221 4.66286C15.32 4.90187 15.2703 5.13806 15.176 5.35767C15.0817 5.57728 14.9445 5.7759 14.7726 5.94194L14.0589 6.65564L11.5137 4.11044L12.2274 3.39674ZM10.2411 5.38304L2.70001 12.9241V15.4693H5.24521L12.7872 7.92824L10.2402 5.38304H10.2411Z"
        fill="#374151"
      />
    </svg>
  );
}

export function DuplicateIcon() {
  return (
    <svg
      width="18"
      height="19"
      className="mr-2 w-6"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.09999 1.96935C7.6226 1.96935 7.16476 2.15899 6.8272 2.49655C6.48963 2.83412 6.29999 3.29196 6.29999 3.76935V10.9693C6.29999 11.4467 6.48963 11.9046 6.8272 12.2421C7.16476 12.5797 7.6226 12.7693 8.09999 12.7693H13.5C13.9774 12.7693 14.4352 12.5797 14.7728 12.2421C15.1103 11.9046 15.3 11.4467 15.3 10.9693V5.94195C15.2999 5.46459 15.1102 5.00683 14.7726 4.66935L12.6 2.49675C12.2625 2.15916 11.8047 1.96945 11.3274 1.96935H8.09999Z"
        fill="#374151"
      />
      <path
        d="M2.70001 7.36934C2.70001 6.89195 2.88965 6.43412 3.22722 6.09655C3.56479 5.75899 4.02262 5.56934 4.50001 5.56934V14.5693H11.7C11.7 15.0467 11.5104 15.5046 11.1728 15.8421C10.8352 16.1797 10.3774 16.3693 9.90001 16.3693H4.50001C4.02262 16.3693 3.56479 16.1797 3.22722 15.8421C2.88965 15.5046 2.70001 15.0467 2.70001 14.5693V7.36934Z"
        fill="#374151"
      />
    </svg>
  );
}

export function GoBackIcon() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_7_4571)">
        <rect
          x="10"
          y="10"
          width="25.33"
          height="25.3306"
          rx="12.665"
          fill="white"
        />
        <path
          d="M25.2012 29C24.8987 28.9999 24.6087 28.8736 24.3948 28.6488L19.8327 23.8547C19.6189 23.63 19.4987 23.3252 19.4987 23.0074C19.4987 22.6896 19.6189 22.3848 19.8327 22.16L24.3948 17.3659C24.5 17.2514 24.6259 17.1601 24.765 17.0973C24.9042 17.0345 25.0538 17.0014 25.2053 17C25.3567 16.9987 25.5069 17.029 25.6471 17.0892C25.7872 17.1495 25.9146 17.2385 26.0217 17.351C26.1287 17.4636 26.2134 17.5974 26.2708 17.7447C26.3281 17.892 26.357 18.0498 26.3557 18.2089C26.3544 18.3681 26.3229 18.5254 26.2631 18.6716C26.2033 18.8178 26.1165 18.9501 26.0075 19.0606L22.2518 23.0074L26.0075 26.9541C26.167 27.1217 26.2756 27.3353 26.3196 27.5677C26.3635 27.8002 26.341 28.0411 26.2547 28.2601C26.1684 28.4791 26.0222 28.6662 25.8347 28.7979C25.6472 28.9296 25.4267 28.9999 25.2012 29Z"
          fill="#1F2A37"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7_4571"
          x="0"
          y="0"
          width="45.33"
          height="45.3307"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0 0.623529 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7_4571"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7_4571"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export function FilterIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.74408 2.74408C2.5878 2.90036 2.5 3.11232 2.5 3.33333V5.48833C2.50005 5.70933 2.58788 5.92126 2.74417 6.0775L8.08917 11.4225C8.24546 11.5787 8.33329 11.7907 8.33333 12.0117V17.5L11.6667 14.1667V12.0117C11.6667 11.7907 11.7545 11.5787 11.9108 11.4225L17.2558 6.0775C17.4121 5.92126 17.5 5.70933 17.5 5.48833V3.33333C17.5 3.11232 17.4122 2.90036 17.2559 2.74408C17.0996 2.5878 16.8877 2.5 16.6667 2.5H3.33333C3.11232 2.5 2.90036 2.5878 2.74408 2.74408Z"
        stroke="#374151"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BellIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6197 13.0862V11.1915C17.6204 9.91088 17.1719 8.6675 16.3466 7.66238C15.5213 6.65725 14.3675 5.94916 13.0718 5.65258C13.0817 5.61584 13.0894 5.57859 13.0948 5.541V3.05262C13.0948 2.77345 12.9795 2.50571 12.7741 2.3083C12.5688 2.1109 12.2904 2 12 2C11.7096 2 11.4312 2.1109 11.2259 2.3083C11.0205 2.50571 10.9052 2.77345 10.9052 3.05262V5.541C10.9106 5.57859 10.9183 5.61584 10.9282 5.65258C9.63249 5.94916 8.47871 6.65725 7.65343 7.66238C6.82815 8.6675 6.37961 9.91088 6.38034 11.1915V13.0862C6.38034 15.5977 4.33633 16.2251 4.33633 17.4808C4.33633 18.105 4.33633 18.7366 4.92534 18.7366H19.0747C19.6637 18.7366 19.6637 18.105 19.6637 17.4808C19.6637 16.2251 17.6197 15.5977 17.6197 13.0862Z"
        fill="#686868"
      />
      <path
        d="M3.09481 12.2441C2.80445 12.2441 2.52598 12.1332 2.32066 11.9358C2.11535 11.7384 2 11.4706 2 11.1915C2.00561 8.69558 2.98957 6.29345 4.75564 4.46418C4.95294 4.25885 5.227 4.13729 5.51752 4.12623C5.80804 4.11518 6.09123 4.21554 6.30479 4.40524C6.51836 4.59493 6.64479 4.85843 6.65629 5.13775C6.66779 5.41708 6.56341 5.68936 6.3661 5.89469C4.97283 7.33592 4.19569 9.22908 4.18962 11.1967C4.18817 11.475 4.07219 11.7414 3.86703 11.9376C3.66187 12.1339 3.38423 12.2441 3.09481 12.2441Z"
        fill="#686868"
      />
      <path
        d="M20.9052 12.2441C20.6148 12.2441 20.3364 12.1332 20.131 11.9358C19.9257 11.7384 19.8104 11.4706 19.8104 11.1915C19.8029 9.22564 19.0259 7.33459 17.6339 5.89469C17.4463 5.68798 17.3501 5.41904 17.3657 5.14514C17.3813 4.87124 17.5075 4.61401 17.7174 4.42824C17.9274 4.24246 18.2044 4.14281 18.4896 4.15051C18.7748 4.15821 19.0456 4.27264 19.2444 4.46944C21.0104 6.29871 21.9944 8.70084 22 11.1967C21.9986 11.475 21.8826 11.7414 21.6774 11.9376C21.4722 12.1339 21.1946 12.2441 20.9052 12.2441Z"
        fill="#686868"
      />
      <path
        d="M8.52179 19.7892C8.81222 20.445 9.29679 21.0043 9.91522 21.3973C10.5337 21.7904 11.2586 22 12 22C12.7414 22 13.4663 21.7904 14.0848 21.3973C14.7032 21.0043 15.1878 20.445 15.4782 19.7892H8.52179Z"
        fill="#686868"
      />
    </svg>
  );
}

export function EditPenTableIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2618 19.2494C16.2618 19.2919 16.2458 19.3327 16.2173 19.3628C16.1887 19.3929 16.15 19.4098 16.1096 19.4098H5.47535C5.43498 19.4098 5.39626 19.3929 5.36771 19.3628C5.33916 19.3327 5.32312 19.2919 5.32312 19.2494V8.04547C5.32312 8.00294 5.33916 7.96214 5.36771 7.93206C5.39626 7.90198 5.43498 7.88509 5.47535 7.88509H12.0167L13.8398 5.9643H5.47535C4.9516 5.96481 4.44945 6.18424 4.0791 6.57443C3.70875 6.96461 3.50048 7.49367 3.5 8.04547V19.2494C3.50048 19.8012 3.70875 20.3303 4.0791 20.7204C4.44945 21.1106 4.9516 21.3301 5.47535 21.3306H16.1096C16.6334 21.3301 17.1355 21.1106 17.5059 20.7204C17.8762 20.3303 18.0845 19.8012 18.085 19.2494V12.3576L16.2618 14.2784V19.2494Z" fill="#686868" />
      <path d="M20.6957 4.17414C20.1828 3.634 19.4874 3.33057 18.7623 3.33057C18.0371 3.33057 17.3417 3.634 16.8288 4.17414L9.73781 11.6441C9.61071 11.7782 9.52412 11.9489 9.48895 12.1348L8.84448 15.5308C8.81744 15.6703 8.8202 15.8145 8.85256 15.9527C8.88493 16.091 8.94608 16.22 9.03159 16.3303C9.1171 16.4406 9.22483 16.5295 9.34697 16.5906C9.46912 16.6516 9.60262 16.6833 9.73781 16.6832C9.79898 16.683 9.86 16.6769 9.92012 16.665L13.1425 15.986C13.319 15.9487 13.4811 15.8571 13.6083 15.7228L20.6984 8.25292C20.9528 7.98511 21.1545 7.66708 21.292 7.31703C21.4296 6.96698 21.5003 6.59179 21.5 6.21293C21.4997 5.83407 21.4286 5.45898 21.2906 5.10914C21.1525 4.75929 20.9504 4.44156 20.6957 4.17414ZM19.4067 5.53213C19.4915 5.62132 19.5587 5.72724 19.6046 5.84383C19.6505 5.96042 19.6741 6.0854 19.6741 6.21161C19.6741 6.33782 19.6505 6.46279 19.6046 6.57938C19.5587 6.69597 19.4915 6.80189 19.4067 6.89108L18.9181 7.40489L17.6292 6.0469L18.1178 5.53213C18.2887 5.35208 18.5205 5.25094 18.7623 5.25094C19.004 5.25094 19.2358 5.35208 19.4067 5.53213ZM12.5117 14.1555L10.9 14.4955L11.2227 12.7965L16.3402 7.40489L17.6292 8.76289L12.5117 14.1555Z" fill="#686868" />
    </svg>
  );
}

export function ExclamationIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 11.25C4.96165 11.25 3.94662 10.9421 3.08326 10.3652C2.2199 9.78834 1.54699 8.9684 1.14963 8.00909C0.752275 7.04978 0.648307 5.99418 0.85088 4.97578C1.05345 3.95738 1.55347 3.02192 2.28769 2.28769C3.02192 1.55347 3.95738 1.05345 4.97578 0.85088C5.99418 0.648307 7.04978 0.752275 8.00909 1.14963C8.9684 1.54699 9.78834 2.2199 10.3652 3.08326C10.9421 3.94662 11.25 4.96165 11.25 6C11.2485 7.39192 10.6949 8.72639 9.71062 9.71062C8.72639 10.6949 7.39192 11.2485 6 11.25ZM6 1.8C5.16932 1.8 4.35729 2.04633 3.66661 2.50783C2.97592 2.96933 2.4376 3.62528 2.11971 4.39273C1.80182 5.16018 1.71865 6.00466 1.8807 6.81938C2.04276 7.6341 2.44277 8.38247 3.03015 8.96985C3.61753 9.55723 4.3659 9.95724 5.18062 10.1193C5.99534 10.2814 6.83982 10.1982 7.60727 9.88029C8.37472 9.56241 9.03067 9.02408 9.49217 8.3334C9.95367 7.64271 10.2 6.83068 10.2 6C10.1987 4.88648 9.75585 3.81892 8.96847 3.03153C8.18109 2.24415 7.11353 1.80125 6 1.8Z" fill="#6B7280" />
      <path d="M6 7.05C5.86076 7.05 5.72723 6.99469 5.62877 6.89623C5.53031 6.79778 5.475 6.66424 5.475 6.525V3.9C5.475 3.76076 5.53031 3.62723 5.62877 3.52877C5.72723 3.43031 5.86076 3.375 6 3.375C6.13924 3.375 6.27278 3.43031 6.37123 3.52877C6.46969 3.62723 6.525 3.76076 6.525 3.9V6.525C6.525 6.66424 6.46969 6.79778 6.37123 6.89623C6.27278 6.99469 6.13924 7.05 6 7.05Z" fill="#6B7280" />
      <path d="M6 8.625C6.28995 8.625 6.525 8.38995 6.525 8.1C6.525 7.81005 6.28995 7.575 6 7.575C5.71005 7.575 5.475 7.81005 5.475 8.1C5.475 8.38995 5.71005 8.625 6 8.625Z" fill="#6B7280" />
    </svg>
  );
}

export function MailIcon() {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4.58008L8.89 9.84008C9.21866 10.0594 9.6049 10.1764 10 10.1764C10.3951 10.1764 10.7813 10.0594 11.11 9.84008L19 4.58008M3 15.5801H17C17.5304 15.5801 18.0391 15.3694 18.4142 14.9943C18.7893 14.6192 19 14.1105 19 13.5801V3.58008C19 3.04965 18.7893 2.54094 18.4142 2.16586C18.0391 1.79079 17.5304 1.58008 17 1.58008H3C2.46957 1.58008 1.96086 1.79079 1.58579 2.16586C1.21071 2.54094 1 3.04965 1 3.58008V13.5801C1 14.1105 1.21071 14.6192 1.58579 14.9943C1.96086 15.3694 2.46957 15.5801 3 15.5801Z" stroke="#237D86" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function SearchIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 17.5L12.5 12.5M14.1667 8.33333C14.1667 9.09938 14.0158 9.85792 13.7226 10.5657C13.4295 11.2734 12.9998 11.9164 12.4581 12.4581C11.9164 12.9998 11.2734 13.4295 10.5657 13.7226C9.85792 14.0158 9.09938 14.1667 8.33333 14.1667C7.56729 14.1667 6.80875 14.0158 6.10101 13.7226C5.39328 13.4295 4.75022 12.9998 4.20854 12.4581C3.66687 11.9164 3.23719 11.2734 2.94404 10.5657C2.65088 9.85792 2.5 9.09938 2.5 8.33333C2.5 6.78624 3.11458 5.30251 4.20854 4.20854C5.30251 3.11458 6.78624 2.5 8.33333 2.5C9.88043 2.5 11.3642 3.11458 12.4581 4.20854C13.5521 5.30251 14.1667 6.78624 14.1667 8.33333Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function WhiteDeleteIcon() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33325 9.99723V14.9972M11.6666 9.99723V14.9972M3.33325 6.6639H16.6666M15.8333 6.6639L15.1108 16.7822C15.0808 17.2027 14.8927 17.5962 14.5842 17.8835C14.2757 18.1708 13.8698 18.3306 13.4483 18.3306H6.55159C6.13004 18.3306 5.72414 18.1708 5.41566 17.8835C5.10717 17.5962 4.91902 17.2027 4.88909 16.7822L4.16659 6.6639H15.8333ZM12.4999 6.6639V4.1639C12.4999 3.94289 12.4121 3.73092 12.2558 3.57464C12.0996 3.41836 11.8876 3.33057 11.6666 3.33057H8.33325C8.11224 3.33057 7.90028 3.41836 7.744 3.57464C7.58772 3.73092 7.49992 3.94289 7.49992 4.1639V6.6639H12.4999Z" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
