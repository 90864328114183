import React from 'react';

interface CustomButtonProps {
  children: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
}
function DataEntryButton({
  children,
  className = '',
  icon,
  disabled = false,
  onClick,
  type = 'button',
}: CustomButtonProps) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
      className={` border px-4 py-2 rounded-lg flex items-center gap-2 hover:text-white hover:bg-[#237D86] border-gray-400 text-sm font-medium ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && <span>{icon}</span>}
      {children}
    </button>
  );
}
export default DataEntryButton;
