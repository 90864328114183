/* eslint-disable react/style-prop-object */
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import {
  CreateAssetCommand,
  useCreateDataEntryAssetMutation,
} from '../../../generated/gql/types';
import DataEntryForm from './DataEntryForm';
import CustomButton from './DataEntryButton';
import { GoodCitySenseOrganizationIdHeader } from '../../../Global';
import { DataEntryAssetProperty } from '../../../AssetHelpers';

function DataEntryRecordCreate({ orgId, closeHandler }: DataEntryRecordCreateProps) {
  const [createAsset, { loading, error }] = useCreateDataEntryAssetMutation({
    context: {
      clientName: 'asset',
      headers: {
        [GoodCitySenseOrganizationIdHeader]: orgId,
      },
    },
  });
  const notifySaved = () => toast.success('Nieuwe meting is opgeslagen', { autoClose: 7000 });

  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateAssetCommand>();

  const onSubmit = handleSubmit((command) => {
    createAsset({
      refetchQueries: [
        'SearchDataEntryAssets',
      ],
      variables: {
        command: {
          name: 'Soil measurement',
          properties: command.properties?.map((property) => {
            if (property.key === DataEntryAssetProperty.SoilExtractionDate) {
              return { ...property, ...{ value: format(parseISO(property.value!), 'y-MM-dd') } };
            }

            return property;
          }).filter((p) => p.value !== undefined && p.value !== '' && p.key !== undefined && p.type !== undefined),
        },
      },
    }).then(() => {
      closeHandler();
      notifySaved();
    }).catch((e) => {
      Sentry.captureMessage(e.toString(), 'error');
    });
  });

  return (
    <form className="flex flex-col min-h-full gap-y-3" onSubmit={onSubmit}>
      <DataEntryForm
        asset={undefined}
        register={register}
        unregister={unregister}
        errors={errors}
        setValue={setValue}
        closeModal={closeHandler}
      />

      {(loading) ? (
        <div className="flex justify-end items-center gap-6">
          <CustomButton type="button" onClick={() => closeHandler()} className="bg-white shadow-lg">Annuleren</CustomButton>
          <CustomButton type="submit" className=" shadow-lg bg-[#237D86] text-white" disabled>Meting opslaan</CustomButton>
        </div>
      ) : (
        <div className="flex justify-end items-center gap-6">
          <CustomButton
            type="button"
            onClick={() => closeHandler()}
            className="bg-white shadow-lg"
          >
            Annuleren
          </CustomButton>
          <CustomButton type="submit" className=" shadow-lg bg-[#237D86] text-white">
            Meting opslaan
          </CustomButton>
        </div>
      )}

      {error && (
        <div className="text-red-600 my-2">
          Sorry er is iets misgegaan met het opslaan van je meting, probeer het later opnieuw.
        </div>
      )}
    </form>
  );
}

type DataEntryRecordCreateProps = {
  orgId: string
  closeHandler: () => void
};

export default DataEntryRecordCreate;
